import React, { Component } from 'react';
import {Container, NavDropdown, Navbar, Nav } from 'react-bootstrap';

export default class Navigation extends Component {
  render() {
	let brand = <h2>Elena Fomicheva</h2>;
    return (
        <Navbar className='nav-top' expand="lg">
			<Container>
				<Navbar.Brand>
					{brand}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse className='collapsedMenu'>
	            	<Nav role="navigation" className='menuLeft'>
						<Nav.Link href="#breathing_method">Breathing Method</Nav.Link>
						<Nav.Link href="#performing">Performing</Nav.Link>
						<Nav.Link href="#teaching">Teaching</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
    );
  }
}
