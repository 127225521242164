import React, { Component } from 'react';
import NavBar from './Navigation'
import Body from './Body'
import Background from './Background'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom-styles.css';



class App extends Component {
  render() {
    return (
      <div className="App">
		<NavBar />
		<Background />
		<Body />
      </div>
    );
  }
}

export default App;
