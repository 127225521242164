import React, { Component } from 'react';
import { Carousel, Form, Container, Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';

export default class Body extends Component {    
	constructor(props) {
		super(props);
	    this.state = {email_body: '', email_subject: ''};
	}
	 
	render(){
		const teachingSlides = [];
		[...Array(15)].map((_, i) =>
			teachingSlides.push(
  				<Carousel.Item key={i}>
					<img src={`/teaching/${i}.JPG`} style={{width: "100%"}} />
				</Carousel.Item>
			)
		);
		
		return(	
			<>
				<Container id="container" fluid>
					<Row>
						<Col md={6} xs={12} sm={6} className="left-column">
							<p>Elena Fomicheva is a pianist with a balance of extraordinary musicianship and courageous intimacy, a mix of tremendous power and absolute
clarity. Grounded in Russian musical school training and tradition, Elena Fomicheva started her piano journey at the age of six in Saint Petersburg, Russia. In 2006 she graduated from the Mussorgsky Music College and was directly accepted into the Saint Petersburg Rimsky-Korsakov State Conservatory, the oldest and leading music Institution in Russia and one of the most prestigious Conservatories in the world.</p>
							<p>Elena’s artistic activity includes appearances in well-known venues such as Glazunov Hall and Glinka Capella in Saint Petersburg, Russia, Rudolfinum Concert Hall in Prague Czech Republic, Mozarteum University in Salzburg, Austria, Philharmonic Hall in Vilnius, Lithuania, Jordan Hall in Boston, Merkin Hall in New York, and Steinway Hall in both in New York City and Miami.</p>
							<p>Elena started her career as a collaborative pianist in 2012 pursuing her Master&#39;s Degree in Collaborative Piano at New England Conservatory in Boston, Massachusetts where she studied with Dr. Cameron Stowe a leading specialist in the study and performance of song recital repertoire, and Jonathan Feldman renowned solo recitalist.</p>
							<p>Receiving much recognition and critical praise during her Master of Music studies, Elena was awarded sponsorship by the Mabel Louise Riley Foundation Scholarship via New England Conservatory.</p>
							<p>Throughout her career as a collaborative pianist, Ms. Fomicheva worked with such noted international artists as Bo Skovhus, Christa Ludwig, Barbara Bonney, Greer Grimsley, and Susan Mentzer.</p>
							<p>In 2015, she joined the Grand Art Concert Series National Tour as a Grand Art Artist in Residence, before becoming the principal Vocal Coach, Musical Director, and Assistant Opera Director at New World School of the Arts in Miami.</p>
						</Col>
						<Col md={6} xs={12} sm={6} className="right-column">
							<Form className="contact-form" > 
								<br/>
								<h3>Contact me.</h3>	
							    <Form.Group>
							        <Form.Label>Subject</Form.Label>
							        <Form.Control type="text" onChange={(e) => this.setState({email_subject: e.target.value})} />
							    </Form.Group>
							    <Form.Group>
							        <Form.Label>Message</Form.Label>
							        <Form.Control type="textarea" onChange={(e) => this.setState({email_body: e.target.value})} />
							    </Form.Group>
							    <Form.Group id="submitButton">
							        <Button type="submit"  onClick={(e) => {
										const body = this.state.email_body;
										const subject = 'Email from website: '+this.state.email_subject;
										window.location.href = `mailto:elena.fomicheva@hotmail.com?body=${body}&subject=${subject}`;
										e.preventDefault();
									}}>
							          Send
							        </Button>
							    </Form.Group>
							  </Form>
						</Col>
					</Row>
				</Container>
				<Container id="breathing_method">
					<hr/>
					<Row>
						<Col>
							<h3>The Fomicheva Breath Management Method<i className="bi bi-badge-tm trademark-icon-h"></i></h3>
							<p>The Fomicheva Breath Management Method<i className="bi bi-badge-tm trademark-icon-t"></i> is a thorough consistent technique designed to improve posture and to facilitate a smooth consistent flow of breath, while helping to calm the cardiovascular system, lowering stress and anxiety levels. These two actions are interconnected and they are crucial to any type of performance, from the basic level to professional stage.</p>
							<a download href="/Method.pdf">Download copy of breathing method <i className="bi bi-cloud-arrow-down"></i></a>
						</Col>
					</Row>
				</Container>
				<Container id="performing">
					<hr/>
					<h3>Performing</h3>
					<Row>
						<Col md={6}>
							<div className="video-container">
								<iframe src="https://www.youtube.com/embed/HSjAFrdZ3_A?showinfo=0&amp;start=15" frameBorder="0" allowfullscreen=""></iframe>
               			 	</div>
						</Col>
						<Col md={6}>
							<div className="video-container">
								<iframe src="https://www.youtube.com/embed/1SajCX_RUdE?showinfo=0" frameBorder="0" allowfullscreen=""></iframe>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<div className="video-container">
								<iframe src="https://www.youtube.com/embed/yKIRfFQx-oA?showinfo=0" frameBorder="0" allowfullscreen=""></iframe>
							</div>
						</Col>
						<Col md={6}>
                			<div className="video-container">
								<iframe src="https://www.youtube.com/embed/bmyjrrcoEtI?showinfo=0" frameBorder="0" allowfullscreen=""></iframe>
							</div>
						</Col>
					</Row>	
					<Row>
						<Col md={12}>
 							<Carousel slide={false}>
      							<Carousel.Item>
									<img src="/performing/first.JPG" style={{width: "100%"}}/>
        							<Carousel.Caption>
          					  			<h3>Grand Art Show</h3>
          					  			<p>Miami, FL</p>
        							</Carousel.Caption>
								</Carousel.Item>
      						    <Carousel.Item>
									<img src="/performing/second.JPG" style={{width: "100%"}} />
        							<Carousel.Caption>
          					  			<h3>Elena Fomicheva and Mitya Nilov</h3>
          					  			<p>New York, NY</p>
        							</Carousel.Caption>
								</Carousel.Item>
								<Carousel.Item>
									<img src="/performing/third.JPG" style={{width: "100%"}} />
        							<Carousel.Caption>
          					  			<h3>Grand Art Show</h3>
          					  			<p>New York, NY</p>
        							</Carousel.Caption>
     						   </Carousel.Item>
							</Carousel>
						</Col>
					</Row>
				</Container>
				<Container>
					<hr/>
					<Row>
						<Col md={12}>
							<h3>Teaching</h3>
						</Col>
					</Row>	
				</Container>
				<Container fluid id="teaching">								
					<Row>
						<Col md={6} xs={12}>
							<a target="_blank" href="https://wsvn.com/news/7spotlight/talented-10-year-old-girl-being-called-piano-prodigy-played-at-carnegie-hall-twice/">
							<img style={{width: "100%"}} src="/teaching/news.JPG" /> <br/>
							As appeared on 7 News</a> &nbsp; <i className="bi bi-box-arrow-up-right"></i>
						</Col>
						<Col md={6} xs={12}>
 							<Carousel slide={false}>
								{teachingSlides}
							</Carousel>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}