import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class Background extends Component {
	constructor(props) {
		super(props);
	    this.state = {height: props.height};
	}
	
	componentDidMount(){
		const headerHeight = 73;
		this.setState({height:window.innerHeight-headerHeight+'px'});
	}
	render() {
		return(
			<div className="outer-background" style={{height: this.state.height}}>
				<div className="inner-image"></div>
				<Container>
		          <Row className="background-text">
					  <Col md={{span:6, offset: 6}} lg={{offset:6}} sm={{span: 6, offset: 6}} >
							<span><i className="glyphicon glyphicon-star" aria-hidden="true"></i> Concert Pianist</span>
							<span><i className="glyphicon glyphicon-star" aria-hidden="true"></i> Collaborative Pianist</span>
							<span><i className="glyphicon glyphicon-star" aria-hidden="true"></i> Opera Vocal Coach</span>
							<span><i className="glyphicon glyphicon-star" aria-hidden="true"></i> Piano Educator</span>
		            	</Col>
		          </Row>
		        </Container>
			</div>
		);
	}
}
